.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom_sc_plan_wrapper.upgrade {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px;
}
.custom_sc_plan_wrapper.upgrade .custom_rsc_box {
  width: 50%;
  padding: 0px 20px;
  text-align: center;
}
.custom_sc_plan_wrapper.upgrade .custom_rsc_box .inner_wrapper {
  position: relative;
}
.custom_sc_plan_wrapper.upgrade .custom_rsc_box .inner_wrapper label {
  padding: 20px 20px;
  display: block;
  position: relative;
  transition: 0.5s;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
}
.custom_sc_plan_wrapper.upgrade .custom_rsc_box .inner_wrapper label::before {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  width: 38px;
  height: 38px;
  background-image: linear-gradient(#000, conic-gradient);
  display: inline-block;
  background: linear-gradient(
    45deg,
    rgba(13, 110, 253, 0) 48%,
    rgb(13, 110, 253) 50%
  );
  opacity: 0;
  transition: 0.5s;
  border-top-right-radius: 10px;
}
.custom_sc_plan_wrapper.upgrade .custom_rsc_box .inner_wrapper label::after {
  content: '';
  position: absolute;
  top: 5px;
  right: 3px;
  width: 11px;
  height: 5px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  border-bottom-left-radius: 3px;
  opacity: 0;
  transition: 0.5s;
}
.custom_sc_plan_wrapper.upgrade .custom_rsc_box .inner_wrapper input {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.000000000000001;
  z-index: 2;
  cursor: pointer;
}
.custom_sc_plan_wrapper.upgrade
  .custom_rsc_box
  .inner_wrapper
  input:checked
  + label {
  border: 2px solid #0d6efd;
}
.custom_sc_plan_wrapper.upgrade
  .custom_rsc_box
  .inner_wrapper
  input:checked
  + label:before,
.custom_sc_plan_wrapper.upgrade
  .custom_rsc_box
  .inner_wrapper
  input:checked
  + label:after {
  opacity: 1;
}
.custom_sc_plan_wrapper.upgrade .custom_rsc_box .title {
  font-size: 20px;
  color: #262626;
  text-transform: capitalize;
}
.custom_sc_plan_wrapper.upgrade .custom_rsc_box .price {
  font-size: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  /*color:#0d6efd;*/
}
.custom_sc_plan_wrapper.upgrade .custom_rsc_box .plan_duration {
  font-size: 16px;
  color: #262626;
  margin-bottom: 0px;
}

.no-btn.btn-primary {
  background-color: rgba(228, 56, 56, 0.63) !important;
}
.no-btn.btn-primary:hover {
  background-color: #d30707 !important;
}
.moda-finner {
  width: 300px;
  max-width: 100%;
  margin: 0px auto !important;
}
.moda-finner .btn-primary {
  width: 100px;
}
