body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Muli" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333539 !important;
  font-size: 14px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Muli";
  src: url("./fonts/Muli.woff2") format("woff2"),
    url("./fonts/Muli.woff") format("woff"),
    url("./fonts/Muli.ttf") format("truetype"),
    url("./fonts/Muli.svg#Muli") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muli";
  src: url("./fonts/Muli-SemiBold.woff2") format("woff2"),
    url("./fonts/Muli-SemiBold.woff") format("woff"),
    url("./fonts/Muli-SemiBold.ttf") format("truetype"),
    url("./fonts/Muli-SemiBold.svg#Muli-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli";
  src: url("./fonts/Muli-Bold.woff2") format("woff2"),
    url("./fonts/Muli-Bold.woff") format("woff"),
    url("./fonts/Muli-Bold.ttf") format("truetype"),
    url("./fonts/Muli-Bold.svg#Muli-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
