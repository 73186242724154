$theme-blue: #3f79cd;
$theme-dark: #333539;
$grey: #c1bebe;
$white: #ffffff;
$light-grey: #f6f9fd;
$off-white:#f5f6f9;

$widget-bg-1: #ff9060;
$widget-bg-2: #3acbe9;
$widget-bg-3: #49ca9e;
$widget-bg-4: #7a40f2;

$sidebar-grey:#9e9ebc;

//for calender
$white: #fff;
$red: #dc3545;
$borderRadius: 5px;
$grayColor: #f1f3f5;
$borderRadius: 5px;
$grayColor: #f1f3f5;
$borderColor: #e1e2e2;
$secondary: #1f2935;
$borderRadius: 5px;
$black: #000;
$primary: #3f79cd;
$borderLight: rgba(225, 226, 226, 0.3);
$boxShadowLight: 5px 10px 30px 2px rgba(17, 37, 77, 0.05);
$facebookLight: #f0f7ff;
$facebook: #3b5998;
$twitterLight: #eefaff;
$twitter: #00aced;
$linkedin: #007bb6;
$linkedinLight: #edf9ff;
$warningColor: #ffc107;
$warning: $warningColor !default;
$dangerColor: #ff6767;
$danger: $dangerColor !default;
$graphBg:linear-gradient(180deg, rgba(31, 144, 250, 0.44) 0%, rgba(31, 144, 250, 0) 100%);