body.black_theme {
  color: #fff !important;
  background-color: #242e42;

  ::-webkit-input-placeholder {
    color: #98a7b9;
  }
  ::-moz-placeholder {
    color: #98a7b9;
  }
  :-ms-input-placeholder {
    color: #98a7b9;
  }
  :-moz-placeholder {
    color: #98a7b9;
  }

  .card {
    background-color: #2f3b52;
    box-shadow: none;
    .card-header {
      background-color: #36435c;
    }
  }

  .revenue_chart,
  .table_list,
  .covid_card {
    background-color: #1f283b;
  }

  .btn-default {
    background-color: #2f3b52 !important;
    color: #fff;
    &:hover {
      background: #414c61 !important;
    }
  }

  .form-control {
    background-color: #343f54 !important;
    border: 1px solid #343f54 !important;
    color: #ffffff !important;
  }

  .form-group {
    label {
      color: #97a6b8;
    }
    .css-yk16xz-control,
    .css-1pahdxg-control {
      background-color: #343f54;
      border-color: #343f54;
    }
    .css-tlfecz-indicatorContainer {
      color: #97a6b8;
    }
    .css-1okebmr-indicatorSeparator {
      background-color: #97a6b8;
    }
    .css-1uccc91-singleValue {
      color: #fff;
    }
    .css-1wa3eu0-placeholder {
      color: #98a7b9;
    }
  }

  .map_input_wrp > input {
    background-color: #343f54;
    border: 1px solid #343f54;
    color: #fff;
  }

  .main_final .left_sidebar .left_sidebar_in .logo_sec {
    .theme_mode {
      background-color: #3f79cd;
      color: #fff;
    }
  }

  .dropzone_main {
    background: #343f54;
  }

  .main_final {
    .left_sidebar {
      background-color: #1f283b;
      box-shadow: none;
      &::after {
        background: #1f283b;
      }
      .left_sidebar_in {
        .left_side_nav {
          > ul {
            > li {
              &.HasSubMenu {
                > ul {
                  > li {
                    &.active {
                      a {
                        color: #fff;
                      }
                    }
                    > a {
                      color: #97a6b8;
                    }
                  }
                }
              }
              &.active {
                > a {
                  color: #3f79cd;
                  svg {
                    path {
                      fill: #3f79cd;
                    }
                  }
                }
              }
              &.active {
                > a {
                  &::before {
                    background: #3f79cd;
                  }
                }
              }
              > a {
                color: #97a6b8;
                svg {
                  path {
                    fill: #97a6b8;
                  }
                }
              }
            }
          }
        }
      }
    }
    .middle_content {
      .middle_content_left {
        background-color: #242e42;
      }
      .middle_content_right {
        background-color: #1f283b;
        box-shadow: none;
        &::before {
          background: #242e42;
        }
      }
    }
  }

  .wlcm_header .wlcm_header_left a {
    color: #fff;
  }

  .upcoming_header {
    .upcoming_header_left {
      .search_area {
        .input_area {
          input {
            background: #273146;
            border-color: #273146;
            color: #fff;
          }
        }
      }
      .icon_area {
        background-color: #273146;
        border-color: #273146;
        color: #98a7b9;
      }
    }
  }

  .table_list {
    table {
      thead {
        tr {
          background-color: #354054;
          th {
            color: #ffffff;
          }
        }
      }
      tbody {
        tr {
          background-color: #273146;
          &:hover {
            background-color: #354054;
          }
          td {
            // border-top: 16px solid #242e42;
            color: #fff;
            border: none;
          }
        }
      }
    }
  }

  .table_action > button {
    background-color: #3e4e6c;
    color: #fff;
  }

  .btn_action {
    .manage_button {
      i {
        color: #ffffff;
      }
    }
    .manage_button_popup {
      background-color: #1f283b;
      border-color: #1f283b;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
      button {
        color: #fff;
        &:hover {
          color: #ffffffb3;
        }
      }
    }
  }

  .page-link {
    background-color: #2f3b52;
    border: 1px solid #2f3b52;
    color: #fff;
    &:hover {
      color: #ffffff;
      background-color: #36435c;
      border-color: #36435c;
    }
  }

  .cal_action {
    .cal_input_icon {
      border-color: #273146;
      background: #273146;
      .icon_wrp {
        i {
          color: #98a7b9;
        }
      }
    }
    .react-datepicker__input-container {
      input[type='text'] {
        background-color: #273146 !important;
        color: #fff;
      }
    }
  }

  .small_calender {
    background-color: #1f283b;
    .date_view {
      .month_date {
        &:hover {
          span {
            background: #3f79cd;
            color: #fff !important;
          }
        }
        &.disable_date {
          span {
            color: #97a6b8;
            opacity: 0.5;
          }
        }
        &.active {
          span {
            background: rgb(54, 67, 92);
          }
        }
        span {
          color: #97a6b8;
        }
      }
    }
    .week_view {
      .week_day {
        color: #97a6b8;
      }
    }
  }

  .need_help_wrp {
    background-color: #36435c;
    .left_sec {
      h3 {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    }
  }

  .user_profile {
    background-color: #36435c;
    a {
      color: #fff;
    }
    .profile_action {
      a {
        svg {
          path,
          circle {
            fill: #fff;
          }
        }
      }
    }
    .btn_logout {
      color: #fff;
      background: #1f283b;
      border-color: #1f283b;
      &:hover {
        background: #fff;
        color: #1f283b;
        svg {
          path {
            fill: #1f283b;
          }
        }
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .sidebar_message_list {
    // background-color: #2f3b52;
    .sidebar_message_list_in {
      .content_area {
        .left_sec {
          h3 {
            color: #98a7b9;
          }
          p {
            color: #8a98aa;
          }
        }
        .right_sec {
          span {
            color: #98a7b9;
          }
        }
      }
    }
  }

  .sidebar_message {
    .header_sec {
      > a {
        background-color: #2f3b52;
        color: #fff;
        &:hover {
          background-color: #1f283b;
        }
      }
    }
  }

  .btn_action.btn_manag .manage_button {
    border-color: #3e4e6c;
    background-color: #3e4e6c;
  }

  .chat_main_wrp {
    &.driver_chat_history {
      background: #263550;
    }
    .users-container .users .person.active {
      background: #1f283b;
    }
    .users-container .users .person:hover {
      background: #1f283b;
    }
    .chat_main_left {
      .chat_title {
        .search_area {
          .form-group {
            background: #343f54;
            .close_icon a {
              color: #fff;
            }
          }
          .search_icon {
            background: #36435c;
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }
      .chat_options {
        a {
          background-color: #36435c;
          color: #fff;
          &.active,
          &:hover {
            background-image: linear-gradient(270deg, #3f79cd 0%, #3580ee 100%);
            color: #fff;
          }
        }
      }
    }
    .chat_main_right {
      .selected-user {
        background: #263550;
        .selected-user-header {
          .selected-user-header-left {
            border-color: #97a6b8;
          }
          .selected-user-header-right {
            .name {
              color: #fff;
            }
            span {
              color: #97a6b8;
            }
          }
        }
      }
    }
  }

  .users-container {
    .users {
      .person {
        &.active {
          .user {
            border-color: #97a6b8;
          }
          .name-time {
            .room-name {
              color: #fff;
            }
            .role_type {
              color: #fff;
            }
          }
        }
        .chat_action {
          > a {
            background-color: #36435c;
            color: #fff;
          }
          a {
            ~ .action_option {
              background-color: #36435c;
              a {
                color: #fff;
              }
            }
          }
        }
        .room-name {
          color: #98a7b9;
        }
        .name-time {
          .role_type {
            color: #8a98aa;
          }
        }
      }
    }
  }

  .chat-container.message-module {
    background: #1f283b;
  }

  .chat-container-in {
    .chat-box {
      .chat-left .chat_text_wrp .chat-hour strong,
      .chat-right .chat_text_wrp .chat-hour strong {
        color: #97a6b8;
      }
      .chat-left {
        .chat_text_wrp {
          .chat-hour {
            span {
              color: #97a6b8;
            }
          }
        }
      }
      .chat-right {
        .chat_text_wrp {
          .chat-hour {
            span {
              color: #97a6b8;
            }
          }
          .chat-text {
            background-color: #263550;
            color: #fff;
          }
        }
      }
    }
    .sendMsg {
      background: #263550;
      input {
        background: #263550;
        color: #fff;
      }
    }
  }

  .user_dropdown_option {
    &:hover {
      > a {
        > i {
          background: #3f79cd;
        }
      }
    }
    a {
      color: #fff;
      transition: all 0.2s linear;
      &:hover {
        color: #ffffffb3;
      }
    }
    > a {
      > i {
        color: #fff;
      }
    }
    ul {
      background: #000;
      &::after {
        border-bottom-color: #000;
      }
    }
  }

  .css-26l3qy-menu {
    background: #343f54;
    .css-1n7v3ny-option {
      background: #3f79cd;
    }
  }

  .sidebar_calender .header_sec svg path {
    fill: #fff;
  }

  .view_details {
    background-color: #242e42 !important;
  }
  .view_driver .card {
    background-color: #1f283b;
  }
  .driver_revenue_chart {
    background: #1f283b;
  }
  .payment_status {
    background-color: #242e42;
  }

  .login_screen_main .login_screen_left {
    .login_form_sec {
      input {
        background: #343f54 !important;
      }
      .submition_area {
        a {
          color: #fff;
        }
      }
    }
    .title_content span {
      color: #fff;
    }
  }

  .item_all_wrp {
    .item_all_dtl .item_all_dtl_in span:nth-child(1) {
      color: #97a6b8;
    }
    .item_all_img .no_img_wrp {
      background: #1f283b;
      color: #2f3b52;
    }
  }

  .drive_data_card {
    .card {
      background-color: #1f283b;
      .card-header {
        background-color: #3f79cd;
      }
    }
  }

  .swal-modal {
    background: #242e42;
  }
  .swal-title {
    color: #fff;
  }

  .list_view {
    border-bottom: 1px solid #79797930;
    &:last-child {
      border: none;
    }
    .list_view_left {
      color: #97a6b8;
    }
  }

  .view_delivery_timeline {
    .vertical-timeline {
      &::before {
        background: #97a6b8;
      }
    }
    .vertical-timeline-element-content {
      background-color: #242e42;
      box-shadow: none !important;
    }
    .vertical-timeline-element-content-arrow {
      border-right-color: #242e42;
    }
  }

  .driver_tab {
    .nav-item {
      &.active-class-driver {
        a {
          background-color: #3f79cd;
        }
      }
      a {
        background-color: #36435c;
      }
    }
  }

  .cal_drive_data .custom_calendar .custom_header {
    background: #36435c;
    border: 1px solid #36435c;
    .custom_header_inner {
      .custom_header_left {
        .custom_date {
          color: #fff;
        }
      }
      .custom_header_right {
        .custom_buttons_group .custom_calendar_button {
          color: #ffffff;
        }
      }
    }
  }

  .modal-backdrop.show {
    opacity: 0.7;
  }

  .modal-content {
    background: #242e42;
    .modal-header {
      border-bottom-color: #79797930;
      .close span {
        color: #fff;
        background: #ffffff40;
      }
    }
    .modal-footer {
      border-top-color: #79797930;
    }
  }

  .status_text {
    &.status_Completed {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
    }
    &.status_Drafts {
      background-color: rgba(227, 230, 12, 0.1);
      color: #e3e60c;
    }
    &.status_Refund {
      color: #d67747;
      background-color: #5c4d45;
    }
  }

  .ql-snow {
    .ql-stroke {
      stroke: #fff;
    }
    .ql-fill,
    .ql-stroke.ql-fill {
      fill: #fff;
    }
  }

  .default_quill {
    .quill {
      border-color: #263550;
    }
    .ql-toolbar {
      background: #263550;
      .ql-formats {
        .ql-picker-label {
          color: #fff;
        }
      }
    }
  }

  .no_data_main .no_data_wrp img {
    opacity: 0.7;
  }

  .pagination .page-item.page-list.active-table-page .page-link {
    background: #1f283b;
  }
  .pagination .page-item .page-link {
    color: #fff;
  }

  // Start For form calendar
  .react-datepicker {
    background-color: #242e42;
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    color: #97a6b8;
    opacity: 0.5;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #97a6b8;
  }
  .react-datepicker__day--today {
    opacity: 1;
    color: #fff;
  }
  .form-group .react-datepicker__day-name {
    color: #fff;
  }
  .react-datepicker__time-container .react-datepicker__time {
    background-color: #242e42;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    color: #97a6b8;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    opacity: 0.5;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    color: #fff;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    color: #fff !important;
  }
  .react-datepicker__day--in-range {
    color: #fff !important;
  }
  .form-group .react-datepicker__day--selected,
  .form-group .react-datepicker__day--in-selecting-range,
  .form-group .react-datepicker__day--in-range,
  .form-group .react-datepicker__month-text--selected,
  .form-group .react-datepicker__month-text--in-selecting-range,
  .form-group .react-datepicker__month-text--in-range,
  .form-group .react-datepicker__quarter-text--selected,
  .form-group .react-datepicker__quarter-text--in-selecting-range,
  .form-group .react-datepicker__quarter-text--in-range,
  .form-group .react-datepicker__year-text--selected,
  .form-group .react-datepicker__year-text--in-selecting-range,
  .form-group .react-datepicker__year-text--in-range {
    background-color: #3f79cd !important;
    color: #fff !important;
  }
  // End For form calendar

  // Start main calendar
  .calendar-inner {
    background-color: transparent !important;
  }
  .calendar-inner .custom_calendar .custom_arrow span {
    border-color: #fff;
  }
  .calendar-inner .custom_calendar .custom_month_day_wrapper .custom_month_day {
    background-color: #2f3b52 !important;
    border-color: #242e42 !important;
  }
  .calendar-inner
    .custom_calendar
    .custom_month_day_list_wrapper
    .custom_month_day {
    background-image: linear-gradient(180deg, #2f3b52 0%, #000000 100%);
    border: 1px solid #242e42 !important;
  }
  .calendar-inner
    .custom_calendar
    .custom_month_day_list_wrapper
    .custom_month_day.disabled {
    background-color: #2b364b !important;
    opacity: 1 !important;
    background-image: none !important;
  }
  .calendar-inner .custom_calendar .custom_month_view {
    border-color: transparent !important;
  }
  .calendar-inner
    .custom_calendar
    .custom_month_day_list_wrapper
    .custom_month_day
    .calendar-data
    span {
    color: #fff;
  }
  .week-name {
    background-color: #242e42 !important;
  }
  .week_box {
    background-color: #2f3b52 !important;
    border-color: #97a6b814 !important;
  }
  .active_day {
    background-color: #97a6b83d !important;
  }
  .custom_day_view {
    background-color: #242e42 !important;
  }
  .day_box {
    background-color: #2f3b52 !important;
    border-color: #97a6b814 !important;
  }
  .calendar-inner
    .custom_calendar
    .custom_month_day_list_wrapper
    .custom_month_day
    .space
    .event-wrap.month-event {
    background-color: #97a6b8;
  }
  // End main calendar

  //--
  .pricing-children-dollar {
    background: #343f55;
    > span {
      border-right-color: #242e42;
      background: #343f54;
    }
  }
  //--
  .table_default {
    .table_loader {
      background: rgba(47, 59, 82, 0.8);
      .loader {
        border-top-color: #ffffff;
        border-left-color: #ffffff;
      }
    }
  }
  .custom_sc_plan_wrapper.upgrade {
    .custom_rsc_box {
      .title,
      .plan_duration {
        color: #fff;
      }
    }
  }
  .current_pan_box {
    background-color: #1f283b;
    .oh_detail_wrapper {
      label {
        color: #fff;
      }
      .current_D_row {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .top_header {
      .active_statue {
        background-color: rgba(0, 128, 0, 0.5);
        color: #fff;
        &:before {
          background-color: #fff;
        }
        &:after {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
