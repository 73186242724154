@import './variable.scss';

.w-150 {
  width: 150px;
}

.min-h-auto {
  min-height: auto !important;
}

select {
  background-image: url('../src/images/select_down_icon.png');
  background-position: right 15px top 15px;
  background-repeat: no-repeat;
  background-size: 15px;
}

a {
  text-decoration: none !important;
}

.text-transform-initial {
  text-transform: initial !important;
}

.color-theme-blue {
  color: $theme-blue;
}

.widget-bg-1 {
  background-color: $widget-bg-1;
}

.widget-bg-2 {
  background-color: $widget-bg-2;
}

.widget-bg-3 {
  background-color: $widget-bg-3;
}

.widget-bg-4 {
  background-color: $widget-bg-4;
}

.w-100 {
  width: 100% !important;
}

.m-0 {
  margin: 0;
}

.m-t-10 {
  margin-top: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-l-10 {
  margin-left: 10px;
}

.text-nowrap {
  white-space: nowrap;
}

.form-group {
  margin-bottom: 15px;
}

.table_list {
  border-radius: 15px;
  background-color: #ffffff;
  padding: 15px;
}

.revenue_chart {
  border-radius: 15px;
  background-color: #ffffff;
  padding: 15px;

  .upcoming_header {
    margin-bottom: 15px;
  }
}

.card {
  border: none !important;
  // box-shadow: 5px 10px 32px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0 14px 4px rgba(238, 238, 238, 0.5);
  border-radius: 10px !important;
  margin-bottom: 20px;

  .card-header {
    background-color: #efefef;
    border: none;
    font-size: 17px;
    border-radius: 10px 10px 0 0 !important;

    b {
      font-weight: 400;
    }
  }

  .card-body {
    .table_list {
      border-radius: initial;
      background-color: inherit;
      padding: 0;
    }
  }
}

.form-control {
  background-color: $white !important;
  border: 1px solid $white !important;
  color: $theme-dark !important;
  padding: 10px 0.75rem !important;
  font-size: 14px !important;

  &:focus {
    box-shadow: none !important;
  }
}

.map_input_wrp > input {
  background-color: $white;
  border: 1px solid $white;
  color: $theme-dark;
  padding: 10px 0.75rem;
  font-size: 14px;
  border-radius: 0.25rem;
  outline: none;
}

.form-group .css-yk16xz-control,
.form-group .css-1pahdxg-control {
  background-color: $white;
  min-height: 43px;
  border-color: $white;
  box-shadow: none;
}

.form-group .css-yk16xz-control:hover {
  border-color: transparent;
}

.modal-body {
  .form-control {
    background-color: $light-grey !important;
    border: 1px solid $light-grey !important;
  }

  .map_input_wrp > input {
    background-color: $light-grey;
    border: 1px solid $light-grey;
  }

  .form-group .css-yk16xz-control,
  .form-group .css-1pahdxg-control {
    background-color: $light-grey;
    border-color: $light-grey;
  }
}

.btn-primary {
  border-radius: 8px;
  background-color: $theme-blue !important;
  border: none;
  font-weight: bold;
  letter-spacing: 0.54px;
  line-height: 18px;
  padding: 11px 20px;
  transition: all 0.2s linear;

  &:hover {
    background: #2457a0 !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn-default {
  border-radius: 8px;
  background-color: #e7f0fd !important;
  border: none;
  font-weight: bold;
  letter-spacing: 0.54px;
  line-height: 18px;
  padding: 11px 20px;
  transition: all 0.2s linear;
  text-decoration: none;

  &:hover {
    background: #dbe7f9 !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.react-datepicker-wrapper {
  display: block !important;
}

.swal-title {
  color: $theme-dark;
  font-size: 18px;
}

label {
  margin-bottom: 5px;
  font-weight: 600;
}

.title {
  font-size: 20px;
  margin: 0;
  line-height: 26px;
  font-weight: bold;
}

//--
.dropzone_main {
  background: $white;
  border-radius: 5px;
  padding: 10px;
  border: 1px dashed #c3c3c3;
  position: relative;
  min-height: 130px;

  .dz_img_wrp {
    position: relative;
    display: inline-block;

    .remove_img {
      position: absolute;
      top: -7px;
      right: 0;
      color: red;
      cursor: pointer;
      z-index: 1;
    }
  }
}

.modal_title {
  font-size: 16px;
  font-weight: bold;

  i {
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    background: $theme-blue;
    border-radius: 0.25rem;
    color: #fff;
    margin-right: 10px;
    font-size: 18px;
  }
}

.modal-header {
  .close {
    border: none;
    background: transparent;
    padding: 0;

    span {
      width: 20px;
      height: 20px;
      background: #3f79cd40;
      display: inline-block;
      line-height: 16px;
      border-radius: 100%;
      color: $theme-blue;
      transition: all 0.2s linear;

      &:hover {
        background-color: $theme-blue;
        color: $white;
      }
    }
  }
}

// Start Radio button
.radio-group-inline {
  display: flex;
  flex-wrap: wrap;
}

.radio-group-inline .radio-group {
  margin-right: 15px;
}

.radio-group-inline .radio-group:last-child {
  margin-right: 0;
}

.radio-group label {
  position: relative;
  padding-left: 30px;
}

.radio-group label input[type='radio'] {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
  opacity: 0;
}

.radio-group label span.radioChecked {
  position: absolute;
  border: 2px solid $theme-blue;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  border-radius: 100%;
}

.radio-group label input:checked ~ .radioChecked:after {
  display: block;
}

.radioChecked:after {
  content: '';
  position: absolute;
  display: none;
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: $theme-blue;
}

// End Radio button

.main_final {
  display: flex;
  flex-wrap: wrap;

  .left_sidebar {
    width: 245px;
    min-height: 100vh;
    padding-bottom: 30px;
    // background-color: $theme-blue;
    position: relative;
    box-shadow: 2px 0 14px 4px rgba(238, 238, 238, 0.5);
    z-index: 2;

    // &::after {
    //   content: '';
    //   width: 40px;
    //   height: 100%;
    //   background: $theme-blue;
    //   position: absolute;
    //   right: -40px;
    //   top: 0;
    //   bottom: 0;
    // }
    .user_profile {
      margin: 0 auto 20px auto;
      max-width: 80%;

      img {
        width: 70px;
        height: 70px;
      }
    }

    &.remove_user_block {
      .left_sidebar_in {
        height: calc(100% - 40px);
      }
    }

    .left_sidebar_in {
      min-height: calc(100% - 250px);
      padding-bottom: 40px;

      .logo_sec {
        text-align: center;
        padding: 35px 0 65px 0;
        position: relative;

        img {
          width: 110px;
        }

        .theme_mode {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background: $theme-blue;
          border-radius: 100%;
          color: $white;
        }
      }
    }

    .btn_logout_wrp {
      text-align: center;
      margin: 0 30px;

      // position: absolute;
      // left: 30px;
      // right: 30px;
      // bottom: 30px;
      .btn_logout {
        color: #28282a;
        border: 1px solid #e1e1e1;
        display: block;
        background: $white;
        border-radius: 4px;
        padding: 8px;
        font-size: 14px;
        transition: all 0.2s linear;

        &:hover {
          background: $theme-blue;
          color: $white;

          svg {
            path {
              fill: $white;
            }
          }
        }

        svg {
          width: 17px;
          margin-right: 10px;
          vertical-align: sub;

          path {
            transition: all 0.2s linear;
          }
        }
      }
    }
  }

  .middle_content {
    width: calc(100% - 245px);
    display: flex;
    flex-wrap: wrap;

    .middle_content_left {
      width: calc(100% - 335px);
      // border-radius: 40px;
      background-color: $light-grey;
      position: relative;
      // z-index: 1;
      padding: 30px;

      .middle_content_header {
        margin-bottom: 30px;
      }
    }

    .middle_content_right {
      width: 335px;
      background-color: $white;
      position: relative;
      padding: 30px 24px;
      box-shadow: 2px 0 14px 4px rgba(238, 238, 238, 0.5);
      // z-index: 2;
      // &::before {
      //   content: '';
      //   width: 40px;
      //   height: 100%;
      //   background: $light-grey;
      //   position: absolute;
      //   top: 0;
      //   bottom: 0;
      //   left: -40px;
      // }
    }
  }
}

.left_side_nav {
  > ul {
    margin: 0;
    padding: 0;

    > li {
      list-style: none;

      &.HasSubMenu {
        &.menu-open {
          > a {
            // &::before{
            //     content: '';
            //     width: 15px;
            //     height: 45px;
            //     background: #ffffff;
            //     position: absolute;
            //     left: 0;
            //     top: 50%;
            //     border-radius: 0 100px 100px 0;
            //     transform: translateY(-50%);
            // }
            > i {
              &::before {
                content: '\f077';
              }
            }
          }

          > ul {
            display: block;
          }
        }

        > ul {
          padding-left: 80px;
          display: none;
          transition: all 0.2s linear;

          > li {
            list-style: none;

            &:hover {
              a {
                color: $theme-blue;
              }
            }

            &.active {
              a {
                color: $theme-blue;
              }
            }

            > a {
              color: $sidebar-grey;
              text-decoration: none;
              transition: all 0.2s linear;
              font-weight: 600;
              padding: 10px 20px 10px 0;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      &.active {
        > a {
          color: $theme-blue;
          font-weight: bold;

          &::before {
            content: '';
            width: 5px;
            height: 45px;
            background: $theme-blue;
            position: absolute;
            right: 0;
            top: 50%;
            border-radius: 100px 0 0 100px;
            transform: translateY(-50%);
          }

          svg {
            path {
              fill: $theme-blue;
            }
          }
        }
      }

      &:hover {
        > a {
          color: $theme-blue;
          font-weight: bold;

          svg {
            path {
              fill: $theme-blue;
            }
          }
        }
      }

      > a {
        display: inline-block;
        width: 100%;
        color: $sidebar-grey;
        text-decoration: none;
        padding: 15px 30px 15px 30px;
        font-weight: 600;
        transition: all 0.2s linear;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        > .red-dot {
          width: 7px;
          height: 7px;
          background: red;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          right: 25px;
          transform: translateY(-50%);
        }

        > i {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }

        svg {
          height: 22px;
          margin-right: 28px;

          path {
            fill: $sidebar-grey;
            transition: all 0.2s linear;
          }
        }
      }
    }
  }
}

.wlcm_header {
  display: flex;
  flex-wrap: wrap;

  .wlcm_header_left {
    .title {
      display: inline-block;
      margin-right: 30px;
    }

    a {
      color: $theme-dark;
      transition: all 0.2s linear;
      text-decoration: underline !important;

      &:hover {
        color: $theme-blue;
      }
    }

    span {
      font-weight: bold;
    }
  }

  a {
    color: $theme-dark;
    transition: all 0.2s linear;

    &:hover {
      color: $theme-blue;
    }
  }

  span {
    font-weight: bold;
  }
}

.widget-dashboard {
  border-radius: 15px;
  padding: 23px 15px 30px 15px;
  color: $white;
  background-image: url('../src/images/widget_bg_img.png');
  background-repeat: no-repeat;
  background-position: bottom right;

  h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  h1 {
    font-size: 30px;
    line-height: normal;
    font-weight: bold;
    margin: 0;
  }
}

.widget_main {
  padding: 20px 0 40px 0;
}

//--
.upcoming_header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  // margin-bottom: 15px;
  .upcoming_header_left {
    display: flex;
    align-items: center;

    > a {
      margin-left: 10px;
    }
  }
}

//--
.upcoming_header_left {
  .search_area {
    width: 212px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;

    .input_area {
      width: 100%;

      input {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        transition: all 0.2s linear;
        padding: 0 50px 0 10px;
        position: relative;
        font-size: 12px;
        font-weight: 600;
        color: #28282a;

        &:focus {
          outline: none;
        }
      }
    }

    .icon_area {
      width: 40px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 40px;
      text-align: center;
      line-height: 38px;
      border-radius: 4px;
      border: 1px solid #e1e1e1;
      background-color: #fafbfe;
      cursor: pointer;
      transition: all 0.2s linear;

      // z-index: 1;
      &:hover {
        background-color: $theme-blue;
        color: $white;
      }
    }
  }
}

.table_list {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;

    thead {
      tr {
        background-color: $off-white;
        border-radius: 4px;

        th {
          white-space: nowrap;
          padding: 16px 15px;
          font-size: 12px;
          font-weight: bold;
          color: #222222;
          text-transform: capitalize;
        }
      }
    }

    tbody {
      tr {
        // background-color: $off-white;
        // border-radius: 4px;
        // margin-bottom: 10px;
        transition: all 0.2s linear;

        &:hover {
          background-color: #fbfbfb;
        }

        td {
          padding: 15px;
          border-top: 1px solid #f3f3f3;
          border-bottom: 1px solid #f3f3f3;
          font-size: 12px;
          color: #222222;

          &:nth-child(1) {
            border-left: 1px solid #f3f3f3;
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-right: 1px solid #f3f3f3;
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }
  }

  nav {
    ul.pagination {
      margin: 10px 0 0 0;
    }
  }
}

.table_action {
  display: flex;
  align-items: center;

  > button {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    background-color: $off-white;
    border: none;
    padding: 0;
    color: #222222;
    font-size: 14px;
    margin: 0 4px;
    transition: all 0.2s linear;

    &:hover {
      color: $white;
      background-color: $theme-blue;
    }
  }
}

.dashboard_table {
  margin-bottom: 30px;
}

//--
.cal_filter_wrp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  margin-bottom: 25px;

  .cal_filter_right {
    display: flex;
    align-items: center;

    .form-group {
      margin-bottom: 0;
    }
  }

  .cal_select {
    width: 120px;
    margin-left: 20px;

    .css-yk16xz-control {
      border-color: #e1e1e1;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    &:first-child {
      margin-left: 0px;
    }
  }
}

.delivery_filter_wrp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 2;
  justify-content: flex-end;

  .cal_select {
    width: 170px;
    margin-right: 20px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }

    .css-yk16xz-control {
      border-color: #e1e1e1;
    }
  }
}

//--
.no_data_main {
  background: transparent !important;

  .no_data_wrp {
    text-align: center;
    font-size: 16px;

    img {
      display: block;
      margin: 0 auto 20px auto;
      width: 150px;
    }
  }
}

.add-new-modal {
  width: 43px;
  height: 43px;
  border: none;
  border-radius: 100%;
  padding: 0;
  background-color: $theme-blue;
  color: $white;
  transition: all 0.2s linear;

  &:hover {
    background: #2457a0;
  }
}

.btn_remove_sub_item {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 0 5px 0 100%;
  padding: 0;
  background-color: $red;
  color: $white;
  transition: all 0.2s linear;

  &:hover {
    background: #2457a0;
  }

  i {
    position: relative;
    top: -3px;
    left: 3px;
  }
}

//--
.user_dropdown_option {
  // position: relative;
  position: absolute;
  right: 20px;
  top: 10px;

  a {
    color: $theme-dark;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
  }

  &:hover {
    > a {
      > i {
        background-color: $white;
        box-shadow: 0 1px 13px rgba(4, 4, 4, 0.17);
      }

      ~ ul {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
      }
    }
  }

  > a {
    > i {
      width: 20px;
      height: 20px;
      display: inline-block;
      text-align: center;
      line-height: 20px;
      border-radius: 100%;
      transition: all 0.2s linear;
    }
  }

  ul {
    opacity: 0;
    visibility: hidden;
    margin: 0;
    padding: 0;
    transform: translateY(30px);
    transition: all 0.3s linear;
    position: absolute;
    border-radius: 5px;
    box-shadow: 0 1px 13px rgba(4, 4, 4, 0.17);
    padding: 15px 25px;
    background: $white;
    right: calc(100% - 35px);
    top: 30px;
    z-index: 99;

    &::after {
      content: '';
      width: 0;
      height: 0;
      display: inline-block;
      border: 9px solid transparent;
      border-top: none;
      border-bottom: 9px solid $white;
      position: absolute;
      top: -9px;
      left: 0;
      right: 0;
      margin: auto 16px 0 auto;
      z-index: 1;
    }

    li {
      list-style: none;
      padding: 7px 0;
      line-height: normal;

      a {
        white-space: nowrap;
      }
    }
  }
}

.user_action_wrp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .btn-primary {
    padding: 16px 24px;
    border-radius: 16px;
    box-shadow: 0 15px 30px rgba(20, 102, 204, 0.16);
    background-image: linear-gradient(270deg, #3f79cd 0%, #3580ee 100%);
  }

  .user_action_dropdown {
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
      width: 38px;
      height: 38px;
      border-radius: 100%;
      object-fit: contain;
    }
  }
}

.sidebar_message {
  padding-top: 30px;

  // padding-bottom: 30px;
  .header_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    > a {
      border-radius: 9px;
      background-color: #eff3fb;
      color: #4d4d4d;
      text-decoration: none;
      font-weight: bold;
      padding: 7px 10px;
      line-height: normal;
      font-size: 12px;
      transition: all 0.2s linear;

      &:hover {
        background-color: #e7f0fd;
        color: $theme-blue;
      }
    }
  }
}

//--
.sidebar_message_list {
  // border-radius: 16px;
  // background-color: $white;
  // padding: 20px 0px 20px 15px;
  // margin-bottom: 20px;
  a {
    display: block;
    width: 100%;
    margin-top: 20px;

    &:nth-child(1) {
      margin-top: 0px;
    }
  }

  .sidebar_message_list_in {
    display: flex;

    &.active {
      .content_area {
        .left_sec {
          h3,
          p {
            color: #333539;
            font-weight: bold;
          }

          h3 {
            .new_msg_label {
              opacity: 1;
              background-color: rgba(255, 0, 162, 0.1);
              color: #ff00a2;
              padding: 0 7px;
              border-radius: 10px;
              text-transform: uppercase;
              margin-left: 5px;
              max-width: 50px;
              display: inline-block;
              text-align: center;
            }
          }
        }

        .right_sec {
          span {
            color: #2c2c2e;
          }
        }
      }
    }

    &:hover {
      .content_area {
        .left_sec {
          h3 {
            color: $theme-blue;
          }
        }
      }
    }

    .img_sec {
      width: 40px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    .content_area {
      width: calc(100% - 40px);
      padding-left: 10px;
      display: flex;
      padding-right: 15px;

      .right_sec {
        width: 50px;
        text-align: right;

        span {
          font-size: 10px;
          line-height: normal;
          color: #bdbdd3;
          white-space: nowrap;
        }
      }

      .left_sec {
        width: calc(100% - 50px);

        h3 {
          color: #bdbdd3;
          font-size: 12px;
          font-weight: 600;
          line-height: normal;
          margin: 0;
          transition: all 0.2s linear;
          text-transform: capitalize;
          display: flex;
          align-items: center;

          .msg_title {
            max-width: calc(100% - 50px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .new_msg_label {
            opacity: 0;
          }
        }

        p {
          color: #8887b0;
          font-size: 10px;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

//--
.need_help_wrp {
  border-radius: 16px;
  background-color: $off-white;
  padding: 15px;
  display: flex;
  align-items: center;
  margin: 40px 10px 30px 10px;

  > div {
    width: 50%;
  }

  .left_sec {
    h3 {
      color: $theme-dark;
      font-size: 18px;
      font-weight: bold;
      white-space: nowrap;
    }

    p {
      opacity: 0.7;
      color: $theme-dark;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 10px;
      font-weight: 600;
    }

    .btn-primary {
      font-size: 12px;
      padding: 6px 10px 7px 10px;
      white-space: nowrap;
    }
  }

  .right_sec {
    img {
      width: 100%;
    }
  }
}

//--
.user_profile {
  border-radius: 16px;
  background-color: $off-white;
  padding: 30px 15px 20px 15px;
  margin: 40px 15px 0px 15px;
  text-align: center;
  position: relative;

  img {
    width: 114px;
    height: 114px;
    object-fit: cover;
    border-radius: 100%;
    margin: 0 auto 5px auto;
    display: block;
  }

  h5 {
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: normal;
    margin-bottom: 2px;
    font-size: 16px;
  }

  a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    font-size: 12px;
  }

  .profile_action {
    position: absolute;
    top: 15px;
    right: 10px;

    a {
      display: inline-block;
      vertical-align: top;
      margin: 0 5px;

      svg {
        width: 17px;
      }
    }
  }
}

.process-bar {
  margin-bottom: 30px;
}

.special_pricing {
  margin-bottom: 30px;
}

//-- Start Date Picker style
.form-group .react-datepicker__day-name,
.form-group .react-datepicker__day,
.form-group .react-datepicker__time-name {
  width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 100% !important;
}

.form-group .react-datepicker__day:hover,
.form-group .react-datepicker__month-text:hover,
.form-group .react-datepicker__quarter-text:hover,
.form-group .react-datepicker__year-text:hover {
  border-radius: 100%;
}

.form-group .react-datepicker__day--selected:hover,
.form-group .react-datepicker__day--in-selecting-range:hover,
.form-group .react-datepicker__day--in-range:hover,
.form-group .react-datepicker__month-text--selected:hover,
.form-group .react-datepicker__month-text--in-selecting-range:hover,
.form-group .react-datepicker__month-text--in-range:hover,
.form-group .react-datepicker__quarter-text--selected:hover,
.form-group .react-datepicker__quarter-text--in-selecting-range:hover,
.form-group .react-datepicker__quarter-text--in-range:hover,
.form-group .react-datepicker__year-text--selected:hover,
.form-group .react-datepicker__year-text--in-selecting-range:hover,
.form-group .react-datepicker__year-text--in-range:hover {
  background: $theme-blue;
}

.form-group .react-datepicker {
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.form-group .react-datepicker__header {
  border: none;
  background-color: $theme-blue;
}

.form-group .react-datepicker__current-month,
.form-group .react-datepicker-time__header,
.form-group .react-datepicker-year-header {
  color: $white;
}

.form-group .react-datepicker__day-name {
  color: $white;
}

.form-group .react-datepicker__navigation--next {
  border-left-color: $white;
}

.form-group .react-datepicker__navigation--previous {
  border-right-color: $white;
}

.form-group .react-datepicker__day--today,
.form-group .react-datepicker__month-text--today,
.form-group .react-datepicker__quarter-text--today,
.form-group .react-datepicker__year-text--today {
  background: $theme-blue;
  color: $white;
}

.form-group .react-datepicker__day--selected,
.form-group .react-datepicker__day--in-selecting-range,
.form-group .react-datepicker__day--in-range,
.form-group .react-datepicker__month-text--selected,
.form-group .react-datepicker__month-text--in-selecting-range,
.form-group .react-datepicker__month-text--in-range,
.form-group .react-datepicker__quarter-text--selected,
.form-group .react-datepicker__quarter-text--in-selecting-range,
.form-group .react-datepicker__quarter-text--in-range,
.form-group .react-datepicker__year-text--selected,
.form-group .react-datepicker__year-text--in-selecting-range,
.form-group .react-datepicker__year-text--in-range {
  background-color: $theme-blue;
}

//-- End Date Picker style

// Start multi select 2
.form-group {
  .css-1rhbuit-multiValue {
    background-color: $theme-blue;
    color: $white;

    .css-12jo7m5 {
      color: $white;
    }
  }
}

// End multi select 2

// Start progressbar
.item_pricing {
  width: 100%;
}

.special_pricing {
  width: 100%;
}

.process-bar {
  width: 100%;

  .StyleItem {
    width: 33.3333333333%;
  }

  .StyleMile {
    width: 66.666666666%;
  }

  .StyleSpecial {
    width: 100%;
  }
}

.progress {
  border-radius: 27.25rem !important;
}

.progress-bar {
  background-color: $theme-blue !important;
}

// End progressbar

// Start Chat Layout
.chat_main_wrp {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  .chat_main_left {
    width: 300px;
  }

  .chat_main_right {
    width: calc(100% - 300px);

    position: relative;
    border-radius: 40px 40px 0 0;
  }
}

.users-container {
  .users {
    margin: 0;
    padding: 0;

    li {
      &:nth-child(1) {
        list-style: none;
      }
    }

    .person {
      cursor: context-menu;
      list-style: none;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 15px 15px 15px 15px;
      transition: all 0.2s linear;

      &.active {
        background: #edf0f6;

        .user {
          border-color: #3f79cd;
          padding: 3px;
        }

        .name-time {
          .room-name {
            text-transform: capitalize;
            color: #333539;
            font-weight: bold;
          }

          .role_type {
            color: #333539;
            font-weight: bold;
          }
        }
      }

      &:hover {
        background: #edf0f6;

        .chat_action {
          opacity: 1;
        }
      }

      .chat_action {
        width: 20px;
        position: relative;
        top: 2px;
        opacity: 0;
        transition: all 0.2s linear;

        &:hover {
          > a ~ .action_option {
            opacity: 1;
            visibility: visible;
          }
        }

        > a {
          width: 17px;
          height: 17px;
          border-radius: 4px;
          background-color: $white;
          display: inline-block;
          text-align: center;
          font-size: 10px;
          color: $theme-dark;
          vertical-align: top;

          svg {
            width: 15px;
            height: 15px;
          }

          &:hover {
            color: $theme-blue;
          }
        }

        a {
          color: $theme-dark;

          ~ .action_option {
            position: absolute;
            right: 0;
            white-space: nowrap;
            background: $white;
            padding: 5px 15px;
            font-size: 12px;
            border-radius: 5px;
            opacity: 0;
            visibility: hidden;
            z-index: 2;
            transition: all 0.2s linear;

            a {
              transition: all 0.2s linear;

              &:hover {
                color: $theme-blue;
              }

              i {
                margin-right: 5px;
              }
            }
          }
        }
      }

      .user {
        width: 40px;
        height: 40px;
        border: 1px solid #e2e2e2;
        border-radius: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-size: 0;
          border-radius: 100%;
        }
      }

      .name-time {
        width: calc(100% - 60px);
        padding-left: 10px;
        padding-right: 30px;

        .role_type {
          color: #8887b0;
          font-size: 10px;
          text-transform: capitalize;

          span {
            display: block;
          }
        }
      }

      .room-name {
        display: block;
        color: $secondary;
        font-size: 12px;
        text-transform: capitalize;
      }

      .message__counter:not(:empty) {
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background: #3f79cd;
        border-radius: 100%;
        text-align: center;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.chat_main_wrp {
  .chat_main_left {
    .chat_title {
      margin: 30px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .search_area {
        .form-group {
          width: 100%;
          left: 0;
          position: absolute;
          top: 0;
          margin: 0;
          display: flex;
          align-items: center;
          background: #fff;
          height: 50px;
          border-radius: 16px;
          padding: 0 10px;
          transform: scale(0);
          transition: all 0.3s ease;
          z-index: 1;

          &.active {
            transform: scale(1);
            transition: all 0.3s ease;
          }

          .icon_sec {
            width: 20px;
            text-align: center;

            svg {
              width: 16px;
              height: 16px;
            }
          }

          .close_icon {
            width: 20px;
            text-align: center;

            a {
              color: grey;

              i {
                font-size: 17px;
              }
            }
          }

          .css-2b097c-container {
            width: calc(100% - 40px);

            .css-1pahdxg-control {
              border-color: transparent !important;
            }

            .css-1hb7zxy-IndicatorsContainer {
              display: none;
            }
          }
        }

        .search_icon {
          width: 34px;
          height: 34px;
          background: $white;
          border-radius: 100%;
          display: inline-block;
          text-align: center;
          line-height: 30px;

          svg {
            width: 16px;
            height: 16px;

            path {
              fill: $theme-blue;
            }
          }
        }
      }
    }

    .chat_options {
      text-align: center;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      a {
        display: inline-block;
        vertical-align: top;
        padding: 7px 40px;
        min-height: 30px;
        border-radius: 10px;
        background-color: #edf0f6;
        color: #333;
        font-weight: bold;
        transition: all 0.2s linear;
        cursor: pointer;

        &.active,
        &:hover {
          background-color: rgba(59, 124, 222, 0.15);
          color: $theme-blue;
        }
      }
    }
  }

  .chat_main_right {
    .selected-user {
      position: absolute;
      padding: 15px;
      top: 30px;
      z-index: 1;
      background: $white;
      left: 30px;
      right: 30px;
      border-radius: 20px;

      .selected-user-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .selected-user-header-left {
          width: 50px;
          height: 50px;
          border: 2px solid $theme-blue;
          border-radius: 100%;
          padding: 4px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
            font-size: 0;
          }
        }

        .selected-user-header-right {
          width: calc(100% - 50px);
          padding-left: 15px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          .name {
            font-weight: bold;
            color: #333539;
            font-size: 14px;
            text-transform: capitalize;
          }

          span {
            display: block;
            font-size: 12px;
            padding: 0;
            color: #8887b0;
          }
        }
      }
    }
  }
}

.chat-container.message-module {
  position: relative;
  background: #9e9ebc1a;
  // border-radius: 0px 40px 0px 0px;
}

//--
.chat-container-in {
  padding: 130px 30px 30px 30px;
  height: 100vh;

  ul {
    margin: 0;
    padding: 0 10px 0 0;

    li {
      list-style: none;
    }
  }

  .chat-box {
    .chat-left {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .chat-avatar {
        width: 40px;
        height: 40px;
        border: 1px solid #e2e2e2;
        border-radius: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          object-fit: cover;
          font-size: 0;
        }
      }

      .chat_text_wrp {
        width: 70%;
        padding-left: 20px;

        .chat-hour {
          margin-bottom: 5px;

          span {
            font-size: 12px;
            margin-left: 20px;
          }
        }

        .chat-text {
          padding: 15px 20px;
          background-color: #3f79cd;
          color: #fff;
          border-radius: 0 25px 25px 30px;
          display: inline-block;
          font-weight: bold;

          img {
            height: 85px;
            width: 85px;
            border-radius: 5px;
            object-fit: contain;
          }
        }
      }
    }

    .chat-right {
      justify-content: flex-end;
      display: flex;
      margin-bottom: 10px;

      .chat-avatar {
        width: 40px;
        height: 40px;
        border: 1px solid #e2e2e2;
        border-radius: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          object-fit: cover;
          font-size: 0;
        }
      }

      .chat_text_wrp {
        width: 70%;
        padding-right: 20px;
        text-align: right;

        .chat-hour {
          margin-bottom: 5px;

          span {
            font-size: 12px;
            margin-left: 15px;
          }
        }

        .chat-text {
          padding: 15px 20px;
          background-color: #dbe6f1;
          color: #223645;
          border-radius: 30px 0 25px 30px;
          display: inline-block;
          font-weight: bold;

          img {
            height: 85px;
            width: 85px;
            border-radius: 5px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.chat-container-in .chat-box .chat-left .chat_text_wrp .chat-hour strong,
.chat-container-in .chat-box .chat-right .chat_text_wrp .chat-hour strong {
  text-transform: capitalize;
  font-size: 12px;
}

// End Chat Layout
.middle_content_left.chat_main_section {
  padding: 0 !important;
}

.chat-container-in {
  .sendMsg {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: #fff;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      width: 100%;
      height: 40px;
      padding: 0;
      margin-right: 10px;
      font-size: 16px;
      color: #647589;
      border: none;

      &:focus {
        outline: none;
      }
    }

    .msg-btn {
      min-height: 42px;
      min-width: 42px;
      border: none;
      border-radius: 100%;
      padding: 0;
      margin-left: 10px;
      background: #3f79cd;
      color: #eff7fe;
      font-size: 17px;

      &.attachment-btn {
        background-color: rgba(63, 121, 205, 0.5%);
      }
    }
  }
}

//--
.custom_calendar {
  .custom_header {
    margin: 0 !important;
    padding: 9px;
    border-radius: 15px 15px 0 0;
    background-color: $theme-blue;

    .custom_header_inner {
      > div {
        width: 33.33%;
      }

      .custom_header_left {
        justify-content: center;

        .custom_date {
          color: $white;
        }
      }

      .custom_header_right {
        text-align: right;

        .custom_buttons_group {
          background: transparent !important;

          .custom_today_button {
            border-right: none !important;
          }

          .custom_calendar_button {
            color: rgba(255, 255, 255, 0.5);
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

//--
.custom_calendar {
  .more.month-more {
    left: 0;
    transform: none;
    font-size: 11px;
    color: #8b97a9;
    transition: all 0.2s linear;

    &:hover {
      color: $theme-blue;
    }
  }
}

//--
.event_modal_layout {
  border: none !important;
  padding: 0 !important;

  .event-date {
    box-shadow: 0 5px 8px rgba(212, 212, 212, 0.57);
    border-radius: 6px 6px 0 0;
    justify-content: initial !important;
    padding: 10px;
    margin-bottom: 10px;

    span {
      i {
        font-size: 18px;
      }
    }
  }
}

// Start calendar default changes
.calendar-inner
  .custom_calendar
  .custom_month_day_list_wrapper
  .custom_month_day
  .calendar-data {
  text-align: left !important;
}

.calendar-inner
  .custom_calendar
  .custom_month_day_list_wrapper
  .custom_month_day {
  background-image: linear-gradient(180deg, #faf7ff 0%, #ffffff 100%);
  border: 1px solid #d7dee8 !important;
}

.calendar-inner .custom_calendar .custom_month_day_wrapper .custom_month_day {
  background-color: #fff !important;
}

// .calendar-inner
//   .custom_calendar
//   .custom_month_day_list_wrapper
//   .custom_month_day.disabled {
//   background-color: #f0f2fb !important;
//   background-image: none;
// }
.calendar-inner
  .custom_calendar
  .custom_month_day_list_wrapper
  .custom_month_day
  .calendar-data
  span {
  color: #8b97a9;
  white-space: nowrap;
}

.calendar-inner
  .custom_calendar
  .custom_month_day_list_wrapper
  .custom_month_day.holiday_main_date {
  background-color: #ff00001a !important;
  background-image: none !important;
}

.calendar-inner
  .custom_calendar
  .custom_month_day_list_wrapper
  .custom_month_day.holiday_main_date
  .calendar-data
  span {
  color: red;
  // font-size: 18px;
}

.custom_calendar .event-list .event-list-item.posted {
  border-radius: 2px;
  border: 1px solid #c8c9fd !important;
  background-color: #d9ddff !important;
  color: #5559ca !important;
}

.custom_calendar .event-list .event-list-item.posted {
  border-left: 3px solid #5559ca !important;
}

.custom_calendar .event-list .event-list-item.block_time {
  border-radius: 2px;
  border: 1px solid #fc5185 !important;
  background-color: #fc518517 !important;
  color: #fc5185 !important;
}

.custom_calendar .event-list .event-list-item.block_time {
  border-left: 3px solid #fc5185 !important;
}

.custom_calendar .event-list .event-list-item.posted.delivery {
  border: 1px solid #dedfe9 !important;
  background-color: #edf1f8 !important;
  color: #9ea6b4 !important;
  border-left: 3px solid #9ea6b4 !important;
}

.custom_calendar .event-list .event-list-item.posted.completed-delivery {
  border: 1px solid #26be00 !important;
  background-color: #8dbc8b !important;
  color: #195914 !important;
  border-left: 3px solid #1c9d2a !important;
}

// End calendar default changes

// Start login screen
.login_screen_main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  background-color: #fff;

  .login_screen_left {
    width: 500px;
    padding: 40px;
    display: flex;
    justify-content: center;

    .Logo_wrp {
      margin-bottom: 50px;
      text-align: center;

      img {
        width: 270px;
        height: auto;
      }
    }

    .title_content {
      margin-bottom: 40px;

      h2 {
        font-size: 22px;
        line-height: 26px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      span {
        font-size: 13px;
        color: grey;
      }
    }

    .login_form_sec {
      input {
        padding: 12px 0.75rem !important;
        background: $light-grey !important;
      }

      .btn-primary {
        padding: 14px 60px;
      }

      .form-group {
        margin-bottom: 25px;
      }

      .submition_area {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .login_screen_right {
    width: calc(100% - 500px);
    background-color: #eff7fe;
    background-image: url('../src/images/home.jpg');
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;

    .login_screen_right_img {
      width: 700px;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }
}

// End login screen

.alert-danger .close {
  border: none;
  padding: 0;
  border-radius: 100%;
  margin-right: 10px;
  background: #84202969;
  color: #fff;

  span {
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    line-height: 17px;
    vertical-align: top;
  }
}

.alert-success .close {
  border: none;
  padding: 0;
  border-radius: 100%;
  margin-right: 10px;
  background: #0f5132a4;
  color: #fff;

  span {
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    line-height: 17px;
    vertical-align: top;
  }
}

//--
.btn_action {
  position: relative;
  margin-left: 15px;
  display: inline-block;

  .manage_button {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;

    i {
      color: $theme-dark;
      font-size: 25px;
    }
  }

  .manage_button_popup {
    position: absolute;
    right: 0;
    box-shadow: 0 2px 8px rgba(212, 212, 212, 0.4);
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    background-color: #ffffff;
    padding: 15px;
    z-index: 1;
    transition: all 0.2s linear;

    &.hide_popup {
      display: none;
      // transform: translateY(-10px);
      // opacity: 0;
      // visibility: hidden;
    }

    button {
      white-space: nowrap;
      border: none;
      background: transparent;
      font-size: 12px;
      margin: 5px 0;
      transition: all 0.2s linear;
      display: block;

      i {
        margin-right: 10px;
      }

      &:hover {
        color: $theme-blue;
      }
    }
  }
}

//--
.btn_action.btn_manag {
  margin-left: 0;

  .manage_button {
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    background-color: $white;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 10px;
    transition: all 0.2s linear;

    &:hover {
      background: $theme-blue;
      border-color: $theme-blue;
      color: $white;

      i {
        color: $white;
      }
    }

    i {
      font-size: 12px;
      margin-left: 10px;
    }
  }
}

//--
.status_text {
  border-radius: 10px;
  text-align: center;
  padding: 3px 8px;
  text-transform: capitalize;
  white-space: nowrap;

  &.status_Requested {
    background-color: rgba(0, 139, 255, 0.1);
    color: #008bff;
  }

  &.status_Pickedup {
    color: #ff9b00;
    background-color: rgba(255, 155, 0, 0.1);
  }

  &.status_Assigned {
    background-color: rgba(0, 206, 125, 0.1);
    color: #00ce7d;
  }

  &.status_Reviewed {
    color: #fc5185;
    background-color: rgba(252, 81, 133, 0.1);
  }

  &.status_Declined {
    background-color: rgba(255, 0, 0, 0.1);
    color: #ff0000;
  }

  &.status_Completed {
    background-color: rgba(76, 56, 46, 0.1);
    color: #4c382e;
  }

  &.status_Drafts {
    background-color: rgba(25, 12, 230, 0.1);
    color: #190ce6;
  }

  &.status_Refund {
    color: #4c3529;
    background-color: #cbbeb7;
  }
}

//--
// .view_details {
//   background-color: #f6f9fd !important;
//   border-radius: 40px 0px 0px 40px !important;
// }

//--
.list_view {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 20px;
  border-bottom: 1px solid #9e9ebc3b;
  line-height: normal;

  &:last-child {
    border-bottom: none;
  }

  .list_view_left {
    width: 230px;
    color: grey;
    text-transform: capitalize;
  }

  .list_view_right {
    width: calc(100% - 230px);
  }
}

//--
.view_delivery_timeline {
  overflow: hidden;

  .vertical-timeline {
    padding: 0;

    &::before {
      background: #ececec;
      width: 2px;
    }
  }

  .vertical-timeline-element {
    margin: 2em 0 !important;
  }

  .vertical-timeline-element-content {
    box-shadow: 0 3px 10px 0px #ddd !important;
    border-radius: 10px;

    .vertical-timeline-element-title {
      font-size: 14px;
      line-height: normal;

      span {
        display: block;
      }
    }

    .vertical-timeline-element-date {
      opacity: 0.4;
      font-weight: 400;
      font-size: 14px !important;
    }
  }

  .vertical-timeline--two-columns {
    .vertical-timeline-element-icon {
      width: 20px !important;
      height: 20px !important;
      margin-left: -10px !important;
      top: 20px !important;
      border: 3px solid #fff;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5) !important;
    }
  }
}

//--
.item_all_wrp {
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  overflow: hidden;
  margin: 10px 0;

  .item_all_img {
    min-height: 200px;

    .no_img_wrp {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      background: #efefef;
      color: $grey;
      font-size: 60px;
      opacity: 1;
    }

    .carousel-indicators {
      li {
        background-color: $theme-blue;
      }
    }

    .carousel-item {
      img {
        object-fit: contain;
      }
    }
  }

  .item_all_dtl {
    padding: 10px;

    .item_all_dtl_in {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        margin: 0;
        min-width: 70px;
        font-weight: 600;
      }

      span {
        &:nth-child(1) {
          width: 80px;
          display: inline-block;
          color: grey;
        }
      }
    }
  }
}

//--
.pickup_slider .carousel-item img {
  object-fit: cover;
}

//--
.driver_tab {
  margin-bottom: 30px !important;

  .nav-item {
    margin-right: 10px;

    &:hover {
      a {
        background-color: $theme-blue;
      }
    }

    &.active-class-driver {
      a {
        background-color: $theme-blue;
      }
    }

    a {
      border-radius: 8px;
      background-color: #c1bebe;
      border: none;
      font-weight: bold;
      letter-spacing: 0.54px;
      line-height: 18px;
      padding: 11px 20px;
      transition: all 0.2s linear;
      color: $white;

      i {
        margin-right: 7px;
        font-size: 18px;
        vertical-align: text-bottom;
        line-height: normal;
      }
    }
  }
}

//--
.drive_data_card {
  .card {
    .card-header {
      background-color: $theme-blue;

      ul.nav {
        justify-content: flex-end;

        .nav-item {
          &:hover {
            a.nav-link {
              color: $white;
            }
          }

          &.active-class-driver {
            a.nav-link {
              color: $white;
            }
          }

          a.nav-link {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            transition: all 0.2s linear;
          }
        }
      }
    }
  }
}

//--
.cal_drive_data {
  .custom_calendar {
    .custom_header {
      background: #faf8ff;
      border: 1px solid rgba(225, 226, 226, 0.3);
      border-bottom: none;

      .custom_header_inner {
        .custom_header_left .custom_date {
          color: $theme-dark;
        }

        .custom_header_right .custom_buttons_group .custom_calendar_button {
          color: $theme-dark;
        }
      }
    }
  }
}

//--
.rating {
  width: 100%;

  .stars-inner {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;

    &::before {
      content: '\f005 \f005 \f005 \f005 \f005';
      color: #ffd700;
    }
  }

  .stars-outer {
    display: inline-block;
    position: relative;
    font-family: 'Font Awesome 5 Pro';
    font-size: 20px;
    color: #909090;

    &::before {
      content: '\f005 \f005 \f005 \f005 \f005';
    }
  }
}

//--
.chat_main_wrp {
  &.driver_chat_history {
    background: $white;
    border-radius: 10px;
    overflow: hidden;
    height: calc(100vh - 130px);

    .chat-container.message-module {
      border-radius: 0;
    }

    .chat_main_right {
      .message-module .scrollbar-container {
        height: calc(100vh - 280px) !important;
      }
    }
  }
}

//--
.cal_action {
  display: flex;
  margin-bottom: 0;

  .react-datepicker__input-container input[type='text'] {
    transition: all 0.2s linear;
    position: relative;
    font-size: 12px;
    font-weight: 600;
    color: #28282a;
    border: none;
    height: 38px;
    width: 100px;
    background-color: $white !important;

    &:focus {
      outline: none;
    }
  }

  .cal_input_icon {
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-left: 15px;

    .icon_wrp {
      i {
        font-size: 16px;
      }
    }
  }
}

//--
.small_calender {
  margin-top: 30px;
  margin-bottom: 30px;
  // border-radius: 16px;
  // background-color: $white;
  // padding: 20px;
  overflow: hidden;
  color: #59748a;

  .week_view {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .week_day {
      width: 14.28%;
      text-align: center;
      font-size: 11px;
      font-weight: bold;
      color: #808080;
      letter-spacing: 0.92px;
    }
  }

  .date_view {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .month_date {
      width: 14.28%;
      text-align: center;
      position: relative;

      &.disable_date {
        span {
          color: #e6e6e6;
        }
      }

      &.event_here {
        &::before {
          content: '';
          width: 5px;
          height: 5px;
          background: #4da1ff;
          border-radius: 100%;
          position: absolute;
          top: 3px;
          right: 12px;
        }
      }

      &.active {
        span {
          background: rgba(77, 161, 255, 0.1);
        }
      }

      &:hover {
        span {
          background: #f5f5f5;
        }
      }

      span {
        width: 28px;
        height: 28px;
        display: inline-block;
        border-radius: 4px;
        font-size: 11px;
        line-height: 28px;
        font-weight: bold;
        color: #999999;
        letter-spacing: 0.92px;
        transition: all 0.2s linear;
        cursor: pointer;
      }
    }
  }

  .header_calender {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;

    button {
      border: none;
      padding: 0;
      background: transparent;
      line-height: normal;

      i {
        font-size: 18px;
        color: $theme-blue;
      }
    }

    .date {
      font-size: 14px;
      font-weight: bold;
      color: #989898;
    }
  }
}

//--

.driver_revenue_chart {
  background: $white;
  border-radius: 10px;
  padding: 20px;

  .upcoming_header {
    margin-bottom: 15px;
  }
}

//--
.sidebar_calender {
  margin-top: 30px;

  .header_sec {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

//--
.driver_rating {
  display: flex;
  flex-wrap: wrap;

  .float-left {
    margin-right: 15px;
  }
}

.payment_status:last-child {
  margin-bottom: 0;
}

.price_model {
  .item_data {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
}

.price_model_text {
  font-size: 22px;
  font-weight: bold;

  .item_data {
    margin: 0;
  }
}

//--
.pricing-children-dollar {
  display: flex;
  background: #f6f9fd;
  border-radius: 5px;

  > span {
    // margin: 0 0px 0 15px;
    font-size: 16px;
    line-height: 43px;
    display: inline-block;
    vertical-align: top;
    border-right: 1px solid #dedede;
    padding: 0 15px;
    border-radius: 4px 0 0 4px;
    background-color: $white;
  }
}

//for loader
.loader {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 3px solid;
  border-top-color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-left-color: #fff;
  border-right-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: loader 1s ease-in-out infinite;
  animation: loader 1s ease-in-out infinite;
  vertical-align: sub;
}

.cai-info {
  margin-bottom: 10px;
  .label {
    font-weight: 700;
    color: #000;
  }
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

//--
.table_default {
  position: relative;
  width: 100%;
  min-height: 200px;

  .table_loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;
    border-radius: 5px;
    width: 100%;

    .loader {
      z-index: 11;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-top-color: $theme-blue;
      border-left-color: $theme-blue;
    }
  }
}

//--
.pagination .page-item.page-list.active-table-page .page-link {
  background: #f5f6f9;
}

.pagination .page-item.page-list.active-table-page .page-link:focus {
  box-shadow: none;
}

.pagination .page-item .page-link {
  color: #222;
}

.not-found-header {
  margin: auto;
}

//--
.covid_card {
  border-radius: 15px;
  background-color: $white;
  padding: 15px;

  .title {
    margin-bottom: 20px;
  }
}

//--
.default_quill {
  margin-bottom: 20px;

  .quill {
    border: 1px solid #ececec;
    border-radius: 15px;
    overflow: hidden;

    .ql-toolbar {
      border: none;
      background: $light-grey;
      padding: 15px 20px;
    }

    .ql-container {
      border: none;
    }
  }
}

.view_delivery_timeline
  .vertical-timeline-element
  .vertical-timeline-element-icon,
.view_delivery_timeline
  .vertical-timeline-element
  .vertical-timeline-element-content {
  animation: none !important;
}

.label_des {
  p {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 16px;
    color: grey;
  }
}

.empty_archive {
  text-align: center;
  margin: 150px 20px 0 20px;

  h4 {
    font-size: 17px;
    font-weight: bold;
  }

  p {
    color: grey;
    font-size: 12px;
  }

  i {
    font-size: 35px;
    margin-bottom: 10px;
  }
}

//--
.sub_item {
  border: 1px solid #3f79cd63;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px;
  background: #3f79cd14;

  > .row {
    margin: 0;
    position: relative;

    .remove_sub_item {
      position: absolute;
      width: auto;
      padding: 0;
      top: -10px;
      right: -10px;
    }
  }
}

.cs_company_sel {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;

  .company {
    display: inline-block;
    padding: 8px 14px 8px 35px;
    // background-color: #3f79cd;
    background-color: #eaecee;
    border-radius: 6px;
    // color: #fff;
    color: #000;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      background-color: #ffffff;
      border-radius: 50%;
      top: 10px;
      left: 9px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: #3f79cd;
      border-radius: 50%;
      top: 15px;
      left: 14px;
      display: none;
    }

    &.active {
      background-color: #3f79cd;
      color: #fff;

      &:after {
        display: block;
      }
    }
  }
}

//--
.reg_wrp {
  margin-top: 50px;
}

.reg_wrp label {
  display: block;
  text-align: center;
  position: relative;
  z-index: 1;
}

.reg_wrp label:before {
  content: '';
  width: 100%;
  height: 1px;
  background: #d5d5d5;
  position: absolute;
  left: 0;
  top: 9px;
}

.reg_wrp label span {
  background: #fff;
  position: relative;
  padding: 0 10px;
}

.reg_wrp a {
  padding: 10px 60px;
  display: block;
  text-align: center;
  background: #3f79cd3b;
  border-radius: 8px;
  color: #3f79cd;
  margin-top: 15px;
  transition: all 0.2s linear;
}

.reg_wrp a:hover {
  background: #3f79cd;
  color: #fff;
}

.plan_row {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #d3e5ff;
  border-radius: 10px;
  padding: 18px 0px;
  margin-bottom: 10px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  color: #5b6b82;
  transition: 0.5s;

  &.header {
    background-color: #fff !important;
  }

  &:hover {
    background-color: #e8eef7;
  }

  &.header .psc_detail_label {
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #000;
  }

  .dsc_wrapper {
    width: 20%;
  }

  p {
    margin: 0px;
    padding: 0px;
  }
}

// .current_pan_boxH_wrapper {
//   min-height: 322px;
//   position: relative;
// }
.current_pan_box {
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
  min-height: 322px;

  .loading {
    width: 100px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .top_header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    span {
      display: inline-block;
    }

    .amount {
      font-size: 40px;
      font-weight: 700;
    }

    .active_statue {
      background-color: rgba(0, 128, 0, 0.1);
      color: green;
      position: relative;
      padding: 10px 20px 10px 40px;
      border-radius: 50px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &::before {
        content: '';
        position: absolute;
        top: 17px;
        left: 20px;
        width: 8px;
        height: 8px;
        background-color: green;
        border-radius: 50%;
      }

      &::after {
        content: '';
        position: absolute;
        top: 13px;
        left: 16px;
        width: 16px;
        height: 16px;
        background-color: rgba(0, 128, 0, 0.2);
        border-radius: 50%;
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .btn_wrapper {
      margin-left: 20px;
    }
  }

  .oh_detail_wrapper {
    display: flex;
    flex-wrap: wrap;
    // margin-left: -20px;
    // margin-right: -20px;
    margin-top: 15px;

    .current_D_row {
      width: 100%;
      // padding: 0px 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // border-right: 1px solid #ddd;
      margin-bottom: 7px;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 12px;

      &:last-child {
        border-bottom: none;
      }

      &:nth-child(even) {
        border-right: none;
      }
    }

    label {
      font-size: 16px;
      color: grey;
      // margin-bottom: 10px;
      margin-bottom: 0px;
      display: block;
      width: 180px;
    }

    .semicolon {
      display: inline-block;
      margin-right: 10px;
    }

    p {
      margin: 0px;
      padding: 0px;
      font-size: 18px;
    }
  }

  .delete_btn,
  .upgrade_btn {
    padding: 6px 20px 10px 20px;
    background-color: #e1e1e1;
    border-radius: 7px;
    font-size: 16px;
    text-decoration: none;
    color: #000;
    border: 1px solid #e1e1e1;
    transition: 0.5s;
    display: inline-block;
  }

  .delete_btn:hover {
    border: 1px solid rgba(231, 122, 122, 0.63);
    background-color: rgba(228, 56, 56, 0.63);
    color: #fff;
  }

  .delete_btn.disable-btn {
    opacity: 0.5;
  }

  .delete_btn.disable-btn .loader {
    border-top-color: #000;
    border-left-color: #000;
    border-bottom-color: rgba(0, 0, 0, 0.5);
    border-right-color: rgba(0, 0, 0, 0.5);
  }

  .delete_btn.disable-btn:hover {
    background-color: #e1e1e1;
    color: #000;
    border: 1px solid #e1e1e1;
  }

  .upgrade_btn {
    &:hover {
      border: 1px solid #000;
      background-color: #000;
      color: #fff;
      background-color: #064cb3;
      border: 1px solid #064cb3;
    }

    background-color: #0d6efd;
    border: 1px solid #0d6efd;
    color: #fff;
    margin-left: 10px;
  }

  .du_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .du_dotted {
    display: inline-block;
    width: 20px;
    height: 1px;
    border-bottom: 2px dotted grey;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.plan_details_wrapper {
  margin-bottom: 30px;
}

// .cn-btn {
//   // border: 1px solid #222222;
//   border-radius: 7px;
//   padding: 5px 20px;
//   background-image: linear-gradient(270deg, #3f79cd 0%, #3580ee 100%);
//   color: #fff;
//   border: none;
// }
// .btn-primary:hover{

// }
.cn-btn.btn-primary {
  padding: 7px 20px;
}

.skeletonLoader_active {
  position: relative;
}

.skeletonLoader_active .home_chart_wrapper .saRe_card_design {
  position: relative;
  height: 130px;
  overflow: hidden;
}

.skeleton_loader_line_wrapper {
  display: none;
}

.skeleton_loader_line_wrapper.skeletonLoader_active {
  display: block;
}

.skeletonLoader_active.skeleton_loader_line_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  // background-color:#e1e1e1;
  background-color: $white;
  z-index: 3;
  padding: 20px;
  display: block;
  overflow: hidden;
  border-radius: 16px;
}

.skeletonLoader_active.skeleton_loader_line_wrapper .line {
  display: inline-block;
  width: 100%;
  height: 15px;
  background-color: #ececec;
  margin-bottom: 18px;
  border-radius: 4px;
}

.skeletonLoader_active.skeleton_loader_line_wrapper .line:nth-child(odd) {
  width: 50%;
}

.skeletonLoader_active.skeleton_loader_line_wrapper .line:nth-child(3n) {
  width: 70%;
}

.skeletonLoader_active.skeleton_loader_line_wrapper:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  z-index: 3;
  background-repeat: repeat-y;
  background-size: 100px 600px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 150% 0;
  }
}

//--
.sub_info_wrp {
  background: #f7f7f7;
  margin: 20px !important;

  .top_header {
    padding: 10px 20px;

    .amount {
      font-size: 25px;
      line-height: normal;
    }

    .active_statue {
      font-size: 12px;
      font-weight: bold;
      padding: 7px 20px 7px 40px;

      &::after {
        top: 9px;
      }

      &::before {
        top: 13px;
      }
    }
  }
}
.already-register {
  background-color: #49ca9e61;
  color: #269972;
  padding: 15px;
  border-radius: 0.25rem;
  margin-bottom: 15px;
}

.btn_back {
  border: none;
  background: transparent;
  color: #3f7ad0;
  font-weight: bold;
  margin-bottom: 20px;
  i {
    margin-right: 5px;
  }
}
